// Generated by Framer (cf240c2)

import { addFonts, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const serializationHash = "framer-z4864"

const variantClassNames = {ZUtYQsJWK: "framer-v-1rw57rt"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const transition2 = {duration: 0, type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "ZUtYQsJWK", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1rw57rt", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"ZUtYQsJWK"} ref={ref ?? ref1} style={{backgroundColor: "rgb(255, 255, 255)", ...style}}><Transition value={transition2}><motion.div background={{alt: "", fit: "fill"}} className={"framer-3crjae"} layoutDependency={layoutDependency} layoutId={"p1mXF7HCQ"} style={{borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10}}><motion.div className={"framer-lbb7ba"} layoutDependency={layoutDependency} layoutId={"p1mXF7HCQuy4W0nRlS"} style={{backgroundColor: "rgb(44, 44, 44)"}}><Image background={{alt: "", fit: "fit", loading: getLoadingLazyAtYPosition(((((componentViewport?.y || 0) + (0 + ((((componentViewport?.height || 724) - 0) - (((componentViewport?.height || 724) - 0) * 1)) / 2))) + 0) + 0 + (((((((((componentViewport?.height || 724) - 0) * 1) - 0) * 1) - 0) - ((((((((componentViewport?.height || 724) - 0) * 1) - 0) * 1) - 0) * 1) + 0)) / 2) + 0 + 0))), pixelHeight: 2400, pixelWidth: 1080, positionX: "center", positionY: "center", sizes: componentViewport?.width || "100vw", src: "https://framerusercontent.com/images/BC33H74yJceZylqkCXZKA8ljyrA.png", srcSet: "https://framerusercontent.com/images/BC33H74yJceZylqkCXZKA8ljyrA.png?scale-down-to=2048 921w,https://framerusercontent.com/images/BC33H74yJceZylqkCXZKA8ljyrA.png 1080w"}} className={"framer-1ub97xa"} layoutDependency={layoutDependency} layoutId={"p1mXF7HCQL15jcYIDo"}/></motion.div></motion.div></Transition></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-z4864.framer-19zda4d, .framer-z4864 .framer-19zda4d { display: block; }", ".framer-z4864.framer-1rw57rt { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 724px; justify-content: center; padding: 0px; position: relative; width: 320px; }", ".framer-z4864 .framer-3crjae { flex: none; height: 100%; overflow: visible; position: relative; width: 100%; }", ".framer-z4864 .framer-lbb7ba { align-content: center; align-items: center; display: flex; flex: none; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 100%; justify-content: center; left: 0px; overflow: visible; padding: 0px; position: absolute; top: 0px; width: 100%; }", ".framer-z4864 .framer-1ub97xa { flex: none; height: 100%; position: relative; width: 100%; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-z4864.framer-1rw57rt, .framer-z4864 .framer-lbb7ba { gap: 0px; } .framer-z4864.framer-1rw57rt > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-z4864.framer-1rw57rt > :first-child { margin-left: 0px; } .framer-z4864.framer-1rw57rt > :last-child { margin-right: 0px; } .framer-z4864 .framer-lbb7ba > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-z4864 .framer-lbb7ba > :first-child { margin-top: 0px; } .framer-z4864 .framer-lbb7ba > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 724
 * @framerIntrinsicWidth 320
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerEPaPAXR3f: React.ComponentType<Props> = withCSS(Component, css, "framer-z4864") as typeof Component;
export default FramerEPaPAXR3f;

FramerEPaPAXR3f.displayName = "SocialArrowsContent";

FramerEPaPAXR3f.defaultProps = {height: 724, width: 320};

addFonts(FramerEPaPAXR3f, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})